$(function(){
  let halfWindowHeight = window.innerHeight / 2;
  let images = $('[data-name="history-image"]');
  let imagesArray = Array.prototype.slice.call(images, 0);

  let slideTexts = $('[data-name="slide-text"]');
  let slideTextsArray = Array.prototype.slice.call(slideTexts, 0);

  if (
    !halfWindowHeight ||
    !imagesArray ||
    !slideTextsArray
  ) return;

  visualizeImage();
  visualizeText();

  document.onscroll = function() {
    visualizeImage();
    visualizeText();
  }

  function visualizeImage() {
    let criterion = this.pageYOffset + halfWindowHeight + 50;
    imagesArray.forEach(function(element) {
      let imageObjectTop = $(element).offset().top;
      if (imageObjectTop > criterion) return;
      element.classList.remove('image--non-visible');
      element.dataset.animation = 'fade-in';
    });
  }

  function visualizeText() {
    let criterion = this.pageYOffset + halfWindowHeight + 150;
    slideTextsArray.forEach(function(element) {
      let textObjectTop = $(element).offset().top;
      if (textObjectTop > criterion) return;
      element.classList.remove('slide--non-visible');
      element.dataset.animation = 'slide-in';
    });
  }

});